import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, cn } from "../lib/helpers";
import { Figure } from "../components/figure";

import { responsiveTitle1, responsiveTitle3 } from "../components/typography.module.css";
import { mobileHeader } from "../components/header.module.css";
import BlockText from "../components/block-text";

import { details, text, root, video, title } from "../components/project-preview.module.css";

export const query = graphql`
  query AboutPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      mainImage {
        asset {
          url
        }
      }
    }
    about: sanityAbout {
      _rawDescription
      _rawTeam(resolveReferences: { maxDepth: 10 })
      _rawContact
    }
    team: allSanityPerson {
      edges {
        node {
          _rawBio
          _rawImage
          name
          role
        }
      }
    }
  }
`;

const AboutPage = (props) => {
    const { data, errors } = props;

    if (errors) {
        return (
            <Layout>
                <GraphQLErrorList errors={errors} />
            </Layout>
        );
    }

    const site = (data || {}).site;
    const team = (data || {}).team ? mapEdgesToNodes(data.team) : [];
    const about = (data || {}).about;
    const seoImage = site.mainImage ? `${site.mainImage.asset.url}?w=1200&h=600` : "";

    if (!site || !about) {
        throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        );
    }

    console.log(team);

    return (
        <Layout>
            <SEO
                title={site.title}
                description={site.description}
                keywords={site.keywords}
                image={seoImage}
            />
            <Container>
                <div className="mt-20 max-w-4xl w-full mx-auto">
                    {/* <h1>Team</h1> */}
                    <div className={"normal-case leading-snug rte"}>
                        <BlockText blocks={about._rawDescription} />
                    </div>

                    {about._rawTeam && (
                        <div className="mt-12">
                            <div>
                                {about._rawTeam.map((member, index) => {
                                    return (
                                        <div
                                            className={cn(root, `${index < team.length - 1 && "mb-6 md:mb-12"}`)}
                                            key={member._key}
                                        >
                                            <div className={cn(video, " flex-auto")}>
                                                {member.image && (
                                                    <div className="flex-1">
                                                        <Figure node={member.image} />
                                                    </div>
                                                )}
                                            </div>
                                            <div className={cn(text, "border-none flex-auto")}>
                                                <h3 className={"text-xl md:text-2xl mb-4 md:mb-6 leading-none -mt-1 md:-mt-0"}>{member.name}</h3>
                                                <ul className={cn(details, "border-none")}>
                                                    <li className="w-full block">
                                                        <h4 className="flex-1 flex w-full">
                                                            Title
                                                        </h4>
                                                        <div>{member.role}</div>
                                                    </li>
                                                    <li className="w-full block">
                                                        <h4 className="flex-1 flex w-full">
                                                            Bio
                                                        </h4>
                                                        <div>
                                                            {member.bio && (
                                                                <div className="">
                                                                    <BlockText blocks={member.bio} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div id="contact" className="mt-24 rte">
                        {about._rawContact && <BlockText blocks={about._rawContact} />}
                    </div>
                </div>
            </Container>
        </Layout>
    );
};

export default AboutPage;
