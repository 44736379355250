import { Link } from "gatsby";
import React from "react";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockText from "./block-text";

import * as styles from "./project-preview.module.css";
import { responsiveTitle3, link } from "./typography.module.css";
import Video from "./video";
import { Figure } from "./figure";

function ProjectPreview(props) {
  console.log({ props });
  return (
    <>
      {props.layout !== "full" ? (
        <Link className={styles.root} to={`/project/${props.slug.current}`}>
          <div className={styles.video}>
            {props._rawPreviewVideo ? (
              <Video
                assetDocument={props._rawPreviewVideo.asset}
                autoplay={true}
                loop={true}
                muted={true}
                showControls={false}
              />
            ) : (
              <>
                {props.mainImage && props.mainImage.asset && (
                  <div className={styles.mainImage}>
                    <Figure node={props.mainImage} />
                  </div>
                )}
              </>
            )}
          </div>
          <div className={styles.text}>
            <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3>
            <ul className={styles.details}>
              {props._rawDescription && (
                <li>
                  <h4>Description</h4>
                  <div>
                    <BlockText blocks={props._rawDescription} />
                  </div>
                </li>
              )}
              {props.categories?.length > 0 && (
                <li>
                  <h4>Credits</h4>
                  <div>
                    {props.categories.map((category, index) => (
                      <React.Fragment>
                        {category.title}
                        {index < props.categories.length - 1 && ", "}
                      </React.Fragment>
                    ))}
                  </div>
                </li>
              )}
              {props.location && (
                <li>
                  <h4>Location</h4>
                  <div>{props.location}</div>
                </li>
              )}
              {props._rawAdditionalInfo && (
                <li>
                  <h4>Details</h4>
                  <div>
                    <BlockText blocks={props._rawAdditionalInfo} />
                  </div>
                </li>
              )}
            </ul>
            <a className={cn(link, "mt-auto mb-2 hidden md:block")} href="">
              View Project
            </a>
          </div>
        </Link>
      ) : (
        <Link className={styles.rootFull} to={`/project/${props.slug.current}`}>
          <div className={styles.videoFull}>
            {props._rawPreviewVideo && (
              <Video
                assetDocument={props._rawPreviewVideo.asset}
                autoplay={true}
                loop={true}
                muted={true}
                showControls={false}
              />
            )}
          </div>
          <div className={styles.textFull}>
            <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3>
          </div>
        </Link>
      )}
    </>
  );
}

export default ProjectPreview;
